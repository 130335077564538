<template>
<div class="editFormation page">
  <headers :title="title" right-text="完成" @clickRight="submit"></headers>
  <div class="scroll">
    <div class="item">
      <div class="label">
        {{type == 1 ? '设置昵称' : '设置手机号'}}
      </div>
      <div class="inputBox">
        <input type="text" v-model="value" :placeholder="type == 1 ? '请输入昵称' : '请输入手机号'">
      </div>
    </div>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";
import { Toast } from 'vant';
export default {
  name: "editFormation",
  components: {
    headers
  },
  data() {
    return {
      type: 1,   // 1 修改昵称  2 修改手机
      title: '',
      value: ''
    }
  },
  mounted() {
    this.type = this.$route.query.type
    if (this.type == 1) {
      this.title = '设置昵称'
    }else {
      this.title = '设置手机'
    }
  },
  methods: {
    submit(){
      if (this.value == '') {
        Toast.fail('请输入内容');
        return;
      }
      let token = this.$store.getters["getToken"]
      if(this.type == 1) {
        $api.set_info({
          token,
          username: this.value
        }).then(res => {
          Toast('修改成功')
          setTimeout(()=>{
            this.$router.back()
          },1000)
        })
      }else {
        $api.set_info_account({
          token,
          account: this.value
        }).then(res => {
          Toast('修改成功')
          setTimeout(()=>{
            this.$router.back()
          },1000)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.editFormation {
  background: #f7f7fe;
  height: 100%;
  .scroll {
    padding-top: 20px;
    .item {
      display: flex;
      align-items: center;
      background-color: #fff;
      height: 44px;
      padding: 0 10px;
      .label {
        color: #646566;
        font-size: 14px;
        width: 100px;
        text-align: left;
        padding-left: 10px;
      }
      .inputBox {
        input {
          border: none;
          outline: none;
          font-size: 14px;
        }
      }
    }
  }
}
</style>